<template>
  <section
    class="ranking-table-header w-full text-texts-standard-additional flex items-center justify-between text-30"
    :class="[
      rankingType,
      { 'no-rewards': hideRewards, 'is-not-firefox': isTournamentTable && !$isFirefox() },
    ]"
  >
    <p class="pos">
      {{ $t('rankingsGroup.pos') }}
    </p>
    <p
      v-if="!hiddenColumns.includes('name')"
      class="name"
      :class="{
        'is-club': isClub,
        'is-club-leaderboard': isClubLeaderboardTable,
        'is-tournament pl-20 mr-auto text-left': isTournamentTable,
      }"
    >
      {{ isClubLeaderboardTable ? $t('club.club') : $t('common.name') }}
    </p>
    <p
      v-if="
        !isClubActivityTable &&
        !isClubLeaderboardTable &&
        !isLeagueTable &&
        !isTournamentResultsTable
      "
      class="level"
    >
      {{ $t('common.level') }}
    </p>
    <p
      v-if="
        !hiddenColumns.includes('club') &&
        !isClubActivityTable &&
        !isClubLeaderboardTable &&
        !isLeagueTable &&
        !isTournamentResultsTable
      "
      class="club"
    >
      {{ $t('rankingsGroup.club') }}
    </p>
    <p v-if="isClubLeaderboardTable" class="points">
      {{ $t('club.members') }}
    </p>
    <p
      v-if="(!isRecordsTable || isClubLeaderboardTable) && !isTournamentTable && !isLeagueTable"
      class="points"
    >
      {{ $t('rankingsGroup.points') }}
    </p>
    <p v-if="isLeagueTable" class="points">
      {{ $te('points') }}
    </p>
    <p v-if="(isDisciplinesTable || isLeagueTable) && !hideRewards" class="rewards">
      {{ $t('common.reward') }}
    </p>
    <p v-if="isRecordsTable" class="season">
      {{ $t('rankingsGroup.season') }}
    </p>
    <p v-if="isRecordsTable" class="result">
      {{ $t('common.power') }}
    </p>
    <p v-if="isTournamentPointsTable" class="points is-tournament">
      {{ $t('tournaments.points') }}
    </p>
    <p v-if="isTournamentTitlesTable" class="points is-tournament">
      {{ $t('tournaments.titles') }}
    </p>
    <p v-if="isTournamentDiscipline2PointsTable" class="points is-tournament">
      {{
        $replacePlaceholder($t('tournaments.specialPoints'), '{partner}', $t('tournaments.ibuECup'))
      }}
    </p>
    <template v-if="isTournamentResultsTable">
      <p
        v-for="i in rounds"
        :key="i"
        class="round is-tournament"
        :class="{
          'is-wide': rounds === 2,
        }"
      >
        {{ $t(`tournaments.r${i}`) }}
      </p>
    </template>
    <p
      v-if="isTournamentResultsTable && !hiddenColumns.includes('result')"
      class="result is-tournament"
    >
      {{ $t('tournaments.performance') }}
    </p>
    <p
      v-if="isTournamentResultsTable || isTournamentDiscipline2PointsTable"
      class="rewards is-tournament"
      :class="{
        'is-wide': hiddenColumns.length === 2,
        'is-narrow': rewardsInfoButton,
        'is-only-one': isTournamentDiscipline2PointsTable,
      }"
    >
      <template v-if="!rewardsInfoButton">{{ $t('tournaments.reward') }}</template>
      <app-icon
        v-else
        icon-name="tournament-rewards"
        :tooltip-text="$t('common.rewards')"
        class="ml-2"
      />
    </p>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { RankingType } from '@/interfaces/RankingsInterfaces'

export default defineComponent({
  name: 'RankingTableHead',
  props: {
    rankingType: {
      type: String,
      default: RankingType.Global,
      validator: (value: RankingType): boolean => {
        return [
          RankingType.Global,
          RankingType.Discipline,
          RankingType.Records,
          RankingType.ClubActivity,
          RankingType.ClubLeaderboard,
          RankingType.TournamentPoints,
          RankingType.TournamentTitles,
          RankingType.TournamentResults,
          RankingType.TournamentPointsDiscipline2,
          RankingType.League,
        ].includes(value)
      },
    },
    isClub: {
      type: Boolean,
      default: false,
    },
    hideRewards: {
      type: Boolean,
      default: false,
    },
    rewardsInfoButton: {
      type: Boolean,
      default: false,
    },
    hiddenColumns: {
      type: Array as PropType<string[]>,
      default: (): string[] => [],
    },
    rounds: {
      type: [Number, null],
      default: null,
    },
  },
  computed: {
    isDisciplinesTable(): boolean {
      return this.rankingType === RankingType.Discipline
    },
    isRecordsTable(): boolean {
      return this.rankingType === RankingType.Records
    },
    isClubActivityTable(): boolean {
      return this.rankingType === RankingType.ClubActivity
    },
    isClubLeaderboardTable(): boolean {
      return this.rankingType === RankingType.ClubLeaderboard
    },
    isTournamentPointsTable(): boolean {
      return this.rankingType === RankingType.TournamentPoints
    },
    isTournamentTitlesTable(): boolean {
      return this.rankingType === RankingType.TournamentTitles
    },
    isTournamentResultsTable(): boolean {
      return this.rankingType === RankingType.TournamentResults
    },
    isTournamentDiscipline2PointsTable(): boolean {
      return this.rankingType === RankingType.TournamentPointsDiscipline2
    },
    isTournamentTable(): boolean {
      return (
        this.isTournamentPointsTable ||
        this.isTournamentTitlesTable ||
        this.isTournamentResultsTable ||
        this.isTournamentDiscipline2PointsTable
      )
    },
    isLeagueTable(): boolean {
      return this.rankingType === RankingType.League
    },
  },
})
</script>

<style lang="scss" scoped>
.ranking-table-header {
  height: 2.9375rem;
  @if $isWsm {
    background: #09172a;
  }
  @if $isSsm {
    background: #292d30;
  }

  &.is-not-firefox {
    padding-right: 1rem;
  }

  .pos {
    width: 6.4375rem;
  }

  .name {
    width: 29rem;

    &.is-tournament {
      width: 30rem;
    }
  }

  .level {
    width: 8.125rem;
  }

  .club {
    width: 20.75rem;
  }

  .points,
  .season,
  .result {
    width: 16.9375rem;

    &.is-tournament {
      width: 15rem;
    }
  }

  .rewards {
    width: 30rem;
    margin-left: 1rem;

    &.is-wide {
      width: 45rem;
    }

    &.is-narrow {
      width: 4.5rem;
    }

    &.is-only-one {
      width: 15rem;
    }
  }

  .is-club {
    width: 42.5rem;
    text-align: left;
  }

  .is-club-leaderboard {
    width: 35.5rem;
    text-align: left;
  }

  .round {
    width: 6.5rem;

    &.is-wide {
      width: 10rem;
    }
  }

  &.disciplines,
  &.records {
    .name {
      width: 34rem;
    }

    .level {
      width: 4.875rem;
    }

    .club {
      width: 34rem;
    }

    .points {
      width: 5.1875rem;
    }
  }
}
</style>
