<template>
  <main v-if="loaded" class="qualification-main flexing flex-col flex-grow w-full">
    <event-message
      v-if="state[0].is_recalculating && state[0]?.activity === 1"
      :in-recalculation="true"
      :timer="state[0].playoff_recalculation_ends"
    />
    <template v-else-if="state[0].state === 'qualification'">
      <event-message
        v-if="state[0].round === 0"
        :not-started="true"
        :timer="config.playoff_start"
      />
      <template v-else-if="state[0].round !== 0">
        <event-message v-if="!isClubPlayoffEligible" club-playoff-not-eligible />
        <div v-else>
          <qualification-sub-header
            :round="subHeaderData"
            :round-count="roundCount"
            class="mb-1"
            @reload-operation="$emit('reloadOperation')"
          />
          <qualification-table
            v-if="results?.players?.length > 0"
            :task-icons="taskIcons"
            :round-count="roundCount"
            :table="results"
            :round="state[0].round"
            :state="state[0].state"
          />
        </div>
      </template>
    </template>
    <template v-else-if="state[0].state === 'draw'">
      <qualification-sub-header
        :round-count="roundCount"
        :title="$te('playoffQualificationEnded')"
        class="mb-1"
      />
      <qualification-table
        v-if="(isMasters ? mastersPlayoffRes : results)?.players?.length > 0"
        :task-icons="isMasters ? {} : taskIcons"
        :round-count="isMasters ? 0 : roundCount"
        :table="isMasters ? mastersPlayoffRes : results"
        :round="isMasters ? 0 : state[0].round"
        :state="state[0].state"
        :masters-ladder="isMasters"
      />
    </template>
  </main>
</template>

<script lang="ts">
import { eventMasterRankings } from '@/globalVariables'
import type { MastersPlayersResponse, TaskIcons } from '@/interfaces/events/QualificationTable'
import { useEventPlayoffStore } from '@/store/pinia/events/playoffStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import EventMessage from '../../EventMessage.vue'
import QualificationSubHeader from './QualificationSubHeader.vue'
import QualificationTable from './QualificationTable.vue'
import type { SubHeaderData } from './QualificationSubHeader.vue'
import { PlayoffState, PlayoffType } from '@/interfaces/events/Playoff'
import type PlayoffStateApiResponse from '@/interfaces/responses/events/playoff/PlayoffStateApiResponse'
import type { PlayoffResultsApiResponse_Qualification } from '@/interfaces/responses/events/playoff/PlayoffResultsApiResponse'
import type { Nullable } from '@/interfaces/utils'

const DEFAULT_ROUND_COUNT = 4

interface ComponentData {
  loaded: boolean
  refreshTimeout: Nullable<ReturnType<typeof setTimeout>>
  mastersPlayoffRes: Nullable<MastersPlayersResponse>
}

export default defineComponent({
  name: 'PlayoffQualificationMain',
  components: {
    QualificationSubHeader,
    QualificationTable,
    EventMessage,
  },
  emits: ['reloadOperation'],
  data(): ComponentData {
    return {
      loaded: false,
      refreshTimeout: null,
      mastersPlayoffRes: null,
    }
  },
  computed: {
    ...mapState(useEventPlayoffStore, {
      config: 'getPlayoffConfig',
      state: 'getPlayoffState',
      _results: 'getPlayoffResults',
    }),
    results(): PlayoffResultsApiResponse_Qualification {
      // Cast to correct type.
      return this._results as PlayoffResultsApiResponse_Qualification
    },
    isMasters(): boolean {
      if (this.$route.query?.tournamentId) {
        return this.state[0]?.playoff_type === PlayoffType.Masters
      }
      return false
    },
    subHeaderData(): SubHeaderData {
      const returnData = {
        round: this.state[0]?.round,
        task: this.state[0]?.task.parameters.find(
          ({ name }: PlayoffStateApiResponse[0]['task']['parameters'][0]): boolean =>
            name === 'translation_key',
        )?.value,
        icon: this.taskIcons[this.state[0]?.round] || '',
        time: this.state[0]?.next_draw,
        disciplineName: this.state[0]?.task.parameters.find(
          ({ name }: PlayoffStateApiResponse[0]['task']['parameters'][0]): boolean =>
            name === 'discipline_id',
        )?.value,
      }

      return returnData
    },
    taskIcons(): TaskIcons {
      const newTaskIcons = {}
      Object.values(this.config?.qualification.tasks || {}).forEach(
        (id: number, i: number): void => {
          newTaskIcons[i + 1] = this.config?.task_icons[id]
        },
      )
      return newTaskIcons
    },
    roundCount(): number {
      if (this.isMasters) return 0
      return (
        Object.keys(this.config?.qualification?.tasks).length ||
        this.state[0].rounds_total ||
        DEFAULT_ROUND_COUNT
      )
    },
    isClubPlayoffEligible(): boolean {
      if (!this._results?.is_club_member) return false
      if (!this._results?.has_enough_club_members) return false
      if (!this._results?.player_in_playoff) return false
      return true
    },
  },
  async mounted(): Promise<void> {
    if (this.state[0].is_recalculating && this.state[0]?.activity === 1) {
      const currentTime = new Date().getTime()
      const recalculationEndsTime = new Date(this.state[0].playoff_recalculation_ends).getTime()

      if (recalculationEndsTime > currentTime) {
        const timeDifference = recalculationEndsTime - currentTime

        this.refreshTimeout = setTimeout((): void => this.$emit('reloadOperation'), timeDifference)
      }
    }
  },
  beforeUnmount(): void {
    if (this.refreshTimeout) clearTimeout(this.refreshTimeout)
  },
  async created(): Promise<void> {
    if (this.isMasters) {
      this.mastersPlayoffRes = await this.$axios.post<{}, MastersPlayersResponse>(
        eventMasterRankings,
      )
      this.loaded = true
      return
    }

    await this.loadPlayoffResults({ state: PlayoffState.Qualification })
    this.loaded = true
  },
  methods: {
    ...mapActions(useEventPlayoffStore, ['loadPlayoffResults']),
  },
})
</script>
