<template>
  <div
    class="tournament-button w-full flex flex-col justify-evenly items-center"
    :class="[
      `is-tournament-${tournament.tournament_state}`,
      `is-user-${tournament.user_state}`,
      isDetail ? 'mt-auto mb-4' : 'flex-grow',
      isDetail && (isDrawing || isEvaluating) ? 'h-48' : null,
    ]"
  >
    <div v-if="isDrawing" class="flex flex-col justify-center items-center flex-grow">
      <p
        class="text-texts-standard-positive font-bold pt-1"
        :class="isDetail ? 'text-36' : 'text-32'"
      >
        {{ $t('tournaments.waitingToStart') }}
      </p>
    </div>
    <div v-else-if="isEvaluating" class="flex flex-col justify-evenly items-center flex-grow">
      <p class="text-texts-standard-important font-bold" :class="isDetail ? 'text-36' : 'text-32'">
        {{ $t('tournaments.waitingForEnd') }}
      </p>
      <app-timer
        v-if="tournament.timer_ends_in > 0"
        :time="tournament.timer_ends_in"
        class="evaluation-timer flexing w-"
        @countdown-action="$emit('reload-data')"
      />
    </div>
    <template v-else>
      <p v-if="isDetail" class="text-30 text-texts-standard-default">
        {{ isRunning ? $t('tournaments.tournamentEndsIn') : $t('tournaments.tournamentStart') }}
      </p>
      <!-- neviditelny timer, aby sa dotiahli data, ked turnaj zacne -->
      <app-timer
        v-if="isJoined && tournament.timer_ends_in > 0"
        v-show="false"
        :time="tournament.timer_ends_in"
        @countdown-action="$emit('reload-data')"
      />
      <p v-if="isRunning" class="time no-bg flexing" :class="{ 'mb-4': isDetail }">
        <app-timer
          v-if="tournament.timer_ends_in > 0"
          :time="tournament.timer_ends_in"
          class="timer flexing"
          @countdown-action="$emit('reload-data')"
        />
      </p>
      <p
        v-else
        class="time flexing text-texts-standard-default"
        :class="isDetail ? 'text-36 mb-4 is-detail' : 'text-32 pt-1'"
      >
        <template v-if="isFinished">
          {{ getFormattedDateFromYMD(tournament.start_date) }}
          <span class="text-texts-standard-important ml-2">
            {{ getFormattedTimeFromHMS(tournament.start_date) }}
          </span>
        </template>
        <span v-else class="text-texts-standard-important font-bold">
          {{ getFormattedTimeFromHMS(tournament.start_date) }}
        </span>
      </p>
      <p
        v-if="isFinished && tournament.position"
        class="position flexing text-36 text-texts-standard-default uppercase"
        :class="`is-position-${tournament.position}`"
      >
        <span class="ico-flag mr-2" />
        <span
          v-html="
            $replacePlaceholder(
              $replaceHtmlPlaceholders(
                $replacePlaceholder($t('tournaments.rankX'), '%s.', '{b} %s. {/b}'),
                'b',
                'font-bold',
              ),
              '%s',
              tournament.position,
            )
          "
        ></span>
      </p>
      <p v-if="isJoined" class="flexing text-40 text-texts-standard-positive font-bold uppercase">
        {{ $t('tournaments.signedUp') }}
      </p>
      <p v-if="isLocked" class="flexing text-32 font-medium text-texts-standard-default uppercase">
        {{ $t('tournaments.disciplineLocked') }}
        <tippy theme="WSM" placement="top" max-width="35rem" class="ml-4">
          <app-icon icon-name="info-44" />
          <template #content>
            <div class="tooltip-content text-texts-standard-default text-30">
              <p
                v-html="
                  $replacePlaceholder(
                    $replaceHtmlPlaceholders(
                      $replacePlaceholder($t('tournaments.reachLevelX'), '%s', '{b} %s {/b}'),
                      'b',
                      'text-texts-standard-important',
                    ),
                    '%s',
                    disciplineUnlockLevel,
                  )
                "
              />
            </div>
          </template>
        </tippy>
      </p>
      <app-button
        v-else-if="isRunningAndCanPlay && !meta.playWithMulligans"
        btn-id="enter-button-exclamation"
        class="enter-button w-full"
        btn-size="md"
        :btn-type="buttonType"
        :disabled="isJoining"
        :loading="isJoining"
        @click="onButtonClick"
      >
        {{ buttonText }}
      </app-button>
      <app-multi-button
        v-else-if="!isJoined && !isFinished"
        class="w-full"
        :class="isRunningAndCanPlay ? 'enter-button' : 'join-button'"
        btn-size="md"
        :btn-type="buttonType"
        :btn-text="buttonText"
        :no-icon="true"
        :disabled="isJoining || isJoinButtonDisabled"
        :loading="isJoining"
        @click="onButtonClick"
      >
        <template #rightPart>
          <span class="text-texts-standard-default">
            {{ buttonPrice }}
          </span>
          <app-main-icon :icon-name="buttonIcon" :icon-size="48" />
        </template>
      </app-multi-button>
      <parameter-popup
        v-if="parameterPopupType === STARTS"
        :parameter="STARTS"
        @close="parameterPopupType = null"
      />
      <no-gems-popup
        v-if="parameterPopupType === GEMS"
        :is-visible="true"
        @close="parameterPopupType = null"
      />
      <info-popup
        v-if="isConfirmPopupOpen"
        :popup-title="$t('tournaments.tournament')"
        class="tournaments-join-confirm-popup"
        width="50.375rem"
        @close="isConfirmPopupOpen = false"
      >
        <div
          class="tournaments-join-confirm-popup-content w-full flex flex-col flex-grow text-36 text-texts-standard-default space-y-6"
        >
          <p class="tournaments-join-confirm-popup-content-header text-center uppercase">
            {{ $t('tournaments.signUpConfirm') }}
          </p>
          <p
            class="tournaments-join-confirm-popup-content-text text-texts-standard-danger text-center"
          >
            {{ $t('tournaments.singOutImpossible') }}
          </p>
          <p class="tournaments-join-confirm-popup-content-text text-center relative top-4">
            {{ $t('tournaments.entryFee') }}:
          </p>
          <div class="w-full flexing">
            <tournament-box :resources="confirmPopupResources" />
          </div>
          <div class="w-full flexing">
            <app-button
              class="join-button"
              btn-size="md"
              :btn-type="buttonType"
              :disabled="isJoining || isJoinButtonDisabled"
              :loading="isJoining"
              @click="joinTournament"
            >
              {{ buttonText }}
            </app-button>
          </div>
        </div>
      </info-popup>
      <info-popup
        v-if="isErrorPopupOpen"
        :popup-title="$t('tournaments.tournament')"
        class="tournaments-error-popup"
        width="50.375rem"
        @close="isErrorPopupOpen = false"
      >
        <div
          class="tournaments-error-popup-content w-full flex flex-col flex-grow text-36 text-texts-standard-default space-y-6"
        >
          <p
            class="tournaments-error-popup-content-text text-texts-standard-danger text-center uppercase"
          >
            {{ $t('tournaments.tournamentFull') }}
          </p>
          <p class="tournaments-error-popup-content-header text-center">
            {{ $t('tournaments.tryOtherTournament') }}
          </p>
          <div class="w-full flexing">
            <app-button
              class="continue-button"
              btn-size="md"
              btn-type="secondary"
              @click="isErrorPopupOpen = false"
            >
              {{ $t('tournaments.continue') }}
            </app-button>
          </div>
        </div>
      </info-popup></template
    >
  </div>
</template>

<script lang="ts">
import {
  MULLIGANS,
  minigameUrl as MINIGAME_URL,
  STARTS,
  GEMS,
  MECHANIC_TOURNAMENTS_DISCIPLINE_2,
} from '@/globalVariables'
import { getFormattedDateFromYMD, getFormattedTimeFromHMS, openMiniGame } from '@/helpers'
import ParameterPopup from '@/components/Popup/Parameter/ParameterPopup.vue'
import NoGemsPopup from '@/components/Popup/NoGemsPopup.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import TournamentBox from './TournamentBox.vue'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useModeStore } from '@/store/pinia/modeStore'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTournamentsDetailStore } from '@/store/pinia/tournaments/useTournamentsDetailStore'
import type { DisciplineMenuData } from '@/interfaces/DisciplineMenu'
import {
  TournamentState,
  TournamentUserState,
  type Tournament,
  type TournamentMeta,
} from '@/interfaces/Tournaments'
import type { RankingUser } from '@/interfaces/RankingsInterfaces'
import { defineComponent, type PropType } from 'vue'
import { mapState, mapActions } from 'pinia'

export enum TournamentButtonContext {
  Calendar = 'Calendar',
  History = 'History',
  Detail = 'Detail',
}

interface ComponentData {
  GEMS: typeof GEMS
  STARTS: typeof STARTS
  MULLIGANS: typeof MULLIGANS
  TournamentState: typeof TournamentState
  TournamentButtonContext: typeof TournamentButtonContext
  parameterPopupType: typeof STARTS | typeof GEMS | null
  isConfirmPopupOpen: boolean
  isErrorPopupOpen: boolean
  isLoading: boolean
  isReloading: boolean
  isJoining: boolean
}

export default defineComponent({
  components: {
    ParameterPopup,
    NoGemsPopup,
    InfoPopup,
    TournamentBox,
  },
  props: {
    tournament: {
      type: Object as PropType<Tournament>,
      required: true,
    },
    meta: {
      type: Object as PropType<TournamentMeta>,
      required: true,
    },
    context: {
      type: String as PropType<TournamentButtonContext>,
      required: true,
    },
  },
  emits: ['reload-data'],
  data(): ComponentData {
    return {
      GEMS,
      STARTS,
      MULLIGANS,
      TournamentState,
      TournamentButtonContext,
      parameterPopupType: null,
      isConfirmPopupOpen: false,
      isErrorPopupOpen: false,
      isLoading: false,
      isReloading: false,
      isJoining: false,
    }
  },
  computed: {
    ...mapState(useCoreStore, ['tournamentIsFull', 'tournamentEnded']),
    ...mapState(useModeStore, { mode: 'getMode' }),
    ...mapState(useDisciplineStore, {
      disciplinesMenuData: 'getDisciplinesMenuData',
    }),
    ...mapState(useResponseTaskStore, {
      starts: 'getStarts',
      gems: 'getGems',
      mulligans: 'getMulligans',
    }),
    ...mapState(useTournamentsDetailStore, ['rankings']),
    isOpen(): boolean {
      return this.tournament.tournament_state === TournamentState.Open
    },
    isJoined(): boolean {
      return this.isOpen && this.tournament.user_state === TournamentUserState.Joined
    },
    isNotJoined() {
      return (
        (this.isOpen || this.isRunning) && this.tournament.user_state !== TournamentUserState.Joined
      )
    },
    isDrawing(): boolean {
      return this.tournament.tournament_state === TournamentState.Drawing
    },
    isRunning(): boolean {
      return this.tournament.tournament_state === TournamentState.Running
    },
    isRunningAndCanPlay(): boolean {
      return this.isRunning && this.tournament.user_state === TournamentUserState.Running
    },
    isRunningAndCanNotPlay(): boolean {
      return this.isRunning && this.tournament.user_state !== TournamentUserState.Running
    },
    isEvaluating(): boolean {
      return this.tournament.tournament_state === TournamentState.Evaluating
    },
    isFinished(): boolean {
      return this.tournament.tournament_state === TournamentState.Finished
    },
    isLocked(): boolean {
      if (this.isDetail) return false
      return (
        this.tournament.user_state === TournamentUserState.Locked ||
        this.disciplineUnlockLevel !== null
      )
    },
    isDetail(): boolean {
      return this.context === TournamentButtonContext.Detail
    },
    buttonType(): string {
      if (this.isRunningAndCanPlay) return 'secondary'
      return this.meta.freeDailyJoins > 0 ? 'confirm' : 'credit'
    },
    buttonText(): string {
      if (this.isRunningAndCanPlay) {
        if (!this.isDetail) return this.$t('tournaments.enter')
        return (
          (this.meta.playWithMulligans
            ? this.$t('tournaments.playAgain')
            : this.$t('tournaments.start')) + (this.buttonRound ? ` (R${this.buttonRound})` : '')
        )
      }
      return this.isRunning ? this.$t('tournaments.join') : this.$t('tournaments.signUp')
    },
    buttonRound(): number {
      // Toto by sa dalo tiez prepisat ako to uz mame nizsie - cez kola a nepotrebovali by sme BE.
      if (this.meta.playMulligansRound) return this.meta.playMulligansRound
      if (this.isDetail && this.rankings?.player?.rounds?.length) {
        // Najdeme si prve kolo, ktore nema vysledok - to kolo sa ide teraz hrat.
        return (
          this.rankings.player.rounds.find(
            (round: RankingUser['rounds'][number]): boolean => round.result === null,
          )?.round ?? 0
        )
      }
      return 0
    },
    buttonPrice(): number {
      if (this.isRunningAndCanPlay && this.meta.playWithMulligans)
        return this.meta.playMulligansPrice
      return this.meta.freeDailyJoins > 0 ? this.meta.joinStartsPrice : this.meta.joinGemsPrice
    },
    buttonIcon(): string {
      if (this.isRunningAndCanPlay && this.meta.playWithMulligans) return MULLIGANS
      return this.meta.freeDailyJoins > 0 ? STARTS : GEMS
    },
    confirmPopupResources(): { value: number; icon: string }[] {
      return this.meta.freeDailyJoins > 0
        ? [{ value: this.meta.joinStartsPrice, icon: STARTS }]
        : [
            { value: this.meta.joinStartsPrice, icon: STARTS },
            { value: this.meta.joinGemsPrice, icon: GEMS },
          ]
    },
    disciplineUnlockLevel(): number {
      return this.disciplinesMenuData?.find(
        (level: DisciplineMenuData): boolean => level.id === this.tournament.discipline_id,
      )?.levelToUnlock
    },
    isJoinButtonDisabled(): boolean {
      // if (this.isNotJoined && this.meta.freeDailyJoins) return this.starts.value < this.meta.joinStartsPrice
      // if (this.isNotJoined && !this.meta.freeDailyJoins) return this.gems.value < this.meta.joinGemsPrice
      return this.meta.playWithMulligans && this.mulligans.value < this.meta.playMulligansPrice
    },
  },
  watch: {
    tournamentIsFull(value: boolean): void {
      if (!value) return
      this.isErrorPopupOpen = true
    },
    tournamentEnded(value: boolean): void {
      if (!value) return
      this.isErrorPopupOpen = true
    },
    isErrorPopupOpen(value: boolean): void {
      if (!value) return
      this.removeAxiosErrors()
      this.$emit('reload-data')
    },
  },
  methods: {
    getFormattedTimeFromHMS,
    getFormattedDateFromYMD,
    ...mapActions(useTournamentsDetailStore, {
      _joinTournament: 'joinTournament',
    }),
    ...mapActions(useCoreStore, ['removeAxiosErrors']),
    async joinTournament(): Promise<void> {
      if (
        this.isNotJoined &&
        !this.meta.freeDailyJoins &&
        this.gems.value < this.meta.joinGemsPrice
      ) {
        this.parameterPopupType = GEMS
        return
      }

      if (this.isNotJoined && this.starts.value < this.meta.joinStartsPrice) {
        this.parameterPopupType = STARTS
        return
      }

      this.isJoining = true
      await this._joinTournament(this.tournament.id)
      this.isJoining = false
      this.isConfirmPopupOpen = false
      if (!this.isDetail) this.$emit('reload-data')
    },
    enterTournament(): void {
      openMiniGame(
        MINIGAME_URL,
        {
          params: {
            mode: this.mode?.tournament,
            discipline_id: this.tournament.discipline_id,
            tournament_id: this.tournament.id,
            tournament_type: this.tournament.mechanic,
          },
        },
        this.$router,
      )
    },
    onButtonClick(): void {
      if (this.isRunningAndCanPlay) {
        if (!this.isDetail) {
          this.$router.push({
            name:
              this.tournament.mechanic === MECHANIC_TOURNAMENTS_DISCIPLINE_2
                ? this.$getWebView('TournamentsDetailDiscipline2')
                : this.$getWebView('TournamentsDetail'),
            params: { id: this.tournament.id },
          })
          return
        }
        return this.enterTournament()
      }
      this.isConfirmPopupOpen = true
    },
  },
})
</script>

<style lang="scss" scoped>
.tournament-button {
  &.is-tournament-open {
    .time:not(.no-bg) {
      width: 25.875rem;
      height: 3.375rem;

      @if $isWsm {
        background-image: linear-gradient(
          to right,
          transparent,
          rgba($color: #6c9ec480, $alpha: 0.5),
          transparent
        );
      }

      @if $isSsm {
        background-image: linear-gradient(
          to right,
          transparent,
          rgba(144, 144, 193, 0.5),
          transparent
        );
      }
    }

    &.is-user-joined {
      .time:not(.no-bg) {
        @if $isWsm {
          background-image: linear-gradient(
            to right,
            transparent,
            rgba(63, 228, 67, 0.5),
            transparent
          );
        }

        @if $isSsm {
          background-image: linear-gradient(
            to right,
            transparent,
            rgba(14, 231, 204, 0.5),
            transparent
          );
        }
      }
    }
  }

  .time.is-detail {
    height: 3.75rem !important;
  }

  .position {
    width: 25.875rem;
    height: 3.563rem;
    @include background(url('#{$path-images}/tournaments/calendar/pos-bg.avif'), contain);

    &.is-position-1 {
      @include background(url('#{$path-images}/tournaments/calendar/pos-1-bg.avif'), contain);
    }

    &.is-position-2 {
      @include background(url('#{$path-images}/tournaments/calendar/pos-2-bg.avif'), contain);
    }

    &.is-position-3 {
      @include background(url('#{$path-images}/tournaments/calendar/pos-3-bg.avif'), contain);
    }

    .ico-flag {
      width: 4.438rem;
      height: 2.625rem;
      @include background(url('#{$path-images}/tournaments/calendar/ico-flag.avif'), contain);
    }
  }

  :deep(.evaluation-timer) {
    min-width: 14rem;
  }
}

.tournaments-join-confirm-popup {
  &-content {
    padding: 2.5rem 1.5rem 1.5rem;

    :deep(.join-button) {
      width: 100% !important;
    }
  }
}

.tournaments-error-popup {
  &-content {
    padding: 2.5rem 1.5rem 1.5rem;

    :deep(.continue-button) {
      width: 100% !important;
    }
  }
}
</style>
