import {
  tournamentsDetailStateEndpoint as TOURNAMENTS_DETAIL_STATE_ENDPOINT,
  tournamentsDetailDisciplineStateEndpoint as TOURNAMENTS_DETAIL_DISCIPLINE_STATE_ENDPOINT,
  tournamentsDetailoinEndpoint as TOURNAMENTS_DETAIL_JOIN_ENDPOINT,
} from '@/globalVariables'
import { extractMetaFromResponse } from '@/helpers'
import { useTournamentsActiveStore } from './useTournamentsActiveStore'
import type { TournamentMeta } from '@/interfaces/Tournaments'
import type { TournamentsDetailStateApiResponse } from '@/interfaces/responses/tournaments/TournamentsDetailStateApiResponse'
import { ApiService } from '@/services/ApiService'
import { defineStore } from 'pinia'

interface TournamentsDetailStore {
  state: TournamentsDetailStateApiResponse | null
}

export const useTournamentsDetailStore = defineStore('tournamentsDetailStore', {
  state: (): TournamentsDetailStore => ({
    state: null,
  }),
  getters: {
    meta(): TournamentMeta {
      return extractMetaFromResponse(this.state)
    },
    tournament(): TournamentsDetailStateApiResponse['tournament'] | null {
      return this.state?.tournament ?? null
    },
    rankings(): TournamentsDetailStateApiResponse['ranking'] | null {
      if (!this.state?.ranking) return null

      const roundCount = this.tournament?.rounds_total ?? 0

      for (const key in this.state.ranking) {
        if (roundCount > 1) {
          this.state.ranking[key].rounds = this.state.ranking[key].rounds || []
          for (let i = this.state.ranking[key].rounds.length; i < roundCount; i++) {
            this.state.ranking[key].rounds.push({ round: i + 1, result: null })
          }
        } else {
          this.state.ranking[key].rounds = null
        }
      }

      return this.state?.ranking ?? null
    },
    rewards(): TournamentsDetailStateApiResponse['rewards'] | null {
      return this.state?.rewards ?? null
    },
  },
  actions: {
    async loadState(tournamentId: number, force: boolean = false): Promise<void> {
      if (this.state && !force) return
      try {
        this.state = await ApiService.get<TournamentsDetailStateApiResponse>(
          TOURNAMENTS_DETAIL_STATE_ENDPOINT + tournamentId,
          { force: true },
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadStateDiscipline(disciplineId: number, force: boolean = false): Promise<void> {
      if (this.state && !force) return
      try {
        this.state = await ApiService.get<TournamentsDetailStateApiResponse>(
          TOURNAMENTS_DETAIL_DISCIPLINE_STATE_ENDPOINT + disciplineId,
          { force: true },
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async joinTournament(tournamentId: number): Promise<void> {
      try {
        this.state = await ApiService.post<TournamentsDetailStateApiResponse>(
          TOURNAMENTS_DETAIL_JOIN_ENDPOINT + tournamentId,
          null,
          {
            force: true,
          },
        )

        useTournamentsActiveStore().loadState(true)
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
